import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";

import Base from "./pages/Base";
import NotFound from "./pages/NotFound/NotFound";

import { Provider } from "react-redux";
import store from "./store/store";
import { refreshAccessToken } from "./config/http";
import Logout from "./pages/Logout/Logout";

store.subscribe(() => {});

const App = () => {
  const excelYear = "2023";
  const currentRefreshSavedYear = localStorage.getItem("refreshSavedYear");
  if (!currentRefreshSavedYear || currentRefreshSavedYear != excelYear) {
    localStorage.removeItem("refreshSavedYear");
    localStorage.removeItem("refreshToken");
    localStorage.setItem("refreshSavedYear", excelYear);
    window.location.reload();
    console.log("A New year, A New Excel");
  }

  useEffect(() => {
    (async () => {
      let index = window.location.href.indexOf("?");
      if (index > -1) {
        const searchString = window.location.href.slice(
          window.location.href.indexOf("?")
        );
        const urlParams = new URLSearchParams(searchString);

        if (urlParams.has("refreshToken")) {
          const refreshToken = urlParams.get("refreshToken");
          if (refreshToken) {
            localStorage.setItem("refreshToken", refreshToken);
          }

          urlParams.delete("refreshToken");
          const newRelativePathQuery = urlParams.toString();
          const newRelativePath =
            newRelativePathQuery.length > 0
              ? window.location.pathname + "?" + newRelativePathQuery
              : window.location.pathname;
          window.open(newRelativePath, "_self");
          await refreshAccessToken();
        }
      }
    })();
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path='/logout' component={Logout} />
          <Route path='/' component={Base} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
